import { Table, Menu, Button, Icon, Dropdown } from 'antd';
import Influencer from 'components/Influencers';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';

const { Item: MenuItem } = Menu;

/**
 * @typedef {{ onRemove: () => void, onViewMessage:  () => void }} ActionButtonProps
 * @type {React.FC<ActionButtonProps>}
 */
const ActionButton = ({ onRemove, onViewMessage }) => {
  const moreOptions = (
    <Menu className="remove-dropdown">
      <MenuItem>
        <span onClick={() => onRemove()} role="presentation">
          REMOVE FROM CAMPAIGN
        </span>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="cell-action">
      <Button
        onClick={() => onViewMessage()}
        shape="circle"
        style={{
          background: '#ffb917',
          border: '0',
          marginRight: '5px',
          width: '35px',
          height: '35px',
        }}
      >
        <IconMessages
          style={{
            position: 'relative',
            top: '2px',
            width: '15px',
            height: '14px',
          }}
        />
      </Button>

      <Dropdown
        overlay={moreOptions}
        placement="bottomRight"
        trigger={['click']}
        className="more-options-btn"
      >
        <Button shape="circle">
          <Icon type="ellipsis" />
        </Button>
      </Dropdown>
    </div>
  );
};

const renderCategories = (categories = []) => {
  return categories.map(x => <div key={x}>{x}</div>);
};

const columns = [
  {
    title: 'CREATOR',
    dataIndex: 'influencerUID',
    key: 'influencerUID',
    render: val => <Influencer.Avatar influencerUID={val} />,
    className: 'pl-0 influencer-width influencer-details',
  },
  {
    title: 'LOCATION',
    dataIndex: 'completeAddress',
    key: 'completeAddress',
    className: 'text-right location',
  },
  {
    title: 'AGE RANGE',
    dataIndex: 'ageRange',
    key: 'ageRange',
    className: 'text-right',
  },
  {
    title: 'GENDER',
    dataIndex: 'gender',
    key: 'gender',
    className: 'text-right',
  },
  {
    title: 'CATEGORY',
    dataIndex: 'categories',
    key: 'categories',
    className: 'text-right',
    render: renderCategories,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'right',
    className: 'actions',
    render: val => <ActionButton {...val} />,
  },
];

/**
 * Product Creators Component
 *
 * @typedef {{
 *   partnershipUIDs: string[],
 *   onInfluencerRemove: (partnershipUID: string) => void,
 *   onViewMessage: (partnershipUID: string) => void,
 *   onViewProfile: (influencerUID: string) => any
 * }} CreatorsProps
 * @type {React.FC<CreatorsProps>}
 */
const Creators = ({ partnershipUIDs, onInfluencerRemove, onViewMessage, onViewProfile }) => {
  const influencers = useSelector(selectors.getInfluencers());
  const partnerships = useSelector(selectors.getPartnerships());

  async function handleRemove(partnershipUID) {
    onInfluencerRemove(partnershipUID);
  }

  async function handleViewMessage(partnershipUID) {
    onViewMessage(partnershipUID);
  }

  function handleViewProfile(influencerUID) {
    return e => {
      if (
        (e.target.tagName !== 'TD' &&
          e.target.closest('td') &&
          e.target.closest('td').className.includes('actions')) ||
        (e.target.closest('li') &&
          e.target.closest('li').className.includes('ant-dropdown-menu-item'))
      ) {
        return;
      }

      onViewProfile(influencerUID);
    };
  }

  const source = partnershipUIDs.map(partnershipUID => {
    const { influencerUID } = partnerships[partnershipUID];
    const influencer = influencers[influencerUID];

    const { address, secondAddress, city, state, zip } = influencer;
    const completeAddress = `${address} ${secondAddress} ${city}, ${state} ${zip}`;

    return {
      key: partnershipUID,
      ...influencer,
      influencerUID,
      completeAddress,
      actions: {
        onRemove: () => handleRemove(partnershipUID),
        onViewMessage: () => handleViewMessage(partnershipUID),
      },
    };
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={source}
        pagination={false}
        className="trend-table"
        onRow={record => ({
          onClick: handleViewProfile(record.uid),
        })}
      />
    </>
  );
};

export default Creators;
