import { Button, Table, Row, Col, Typography } from 'antd';
import Influencer from 'components/Influencers';
import RehireInfluencer from 'components/RehireInfluencer';
import { POST_STATUS } from 'constants/posts';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

const { Text } = Typography;

const Action = ({ partnership, influencerUID, showModal, status }) => {
  let shippedBtn = (
    <Button onClick={() => showModal()} className="trend-btn">
      Mark As Shipped
    </Button>
  );
  let label = null;

  if (partnership.rehireInviteAcceptedAt) {
    label = `Last Job Accepted: ${new Date(
      partnership.rehireInviteAcceptedAt * 1000
    ).toLocaleDateString()}`;
  } else if (partnership.rehireInviteDeclinedAt) {
    label = `Last Job Declined: ${new Date(
      partnership.rehireInviteDeclinedAt * 1000
    ).toLocaleDateString()}`;
  }

  if (status !== POST_STATUS.needsShipping) {
    shippedBtn = (
      <div>
        <Row>
          <Col span={12}>
            <Button onClick={() => showModal()} className="trend-btn secondary">
              Shipping Details
            </Button>
          </Col>

          <Col span={12}>
            <RehireInfluencer.Button
              influencerUID={influencerUID}
              awaitingLabel={
                <div>
                  Awaiting
                  <br />
                  Rehire Response
                </div>
              }
            >
              Rehire Creator
            </RehireInfluencer.Button>
            <Text type="secondary" className="rehire-label">
              {label}
            </Text>
          </Col>
        </Row>
      </div>
    );
  }

  return <div className="cell-action">{shippedBtn}</div>;
};

const Address = ({ name: influencerName, address, city, state, zip, secondAddress }) => (
  <div
    style={{
      lineHeight: '1.64',
    }}
    className="size-11px text-black"
  >
    <div className="type-sfpro-bold">{influencerName}</div>
    <div>
      {address} {secondAddress}
    </div>
    <div>
      {city}, {state}, {zip}
    </div>
  </div>
);

const columns = [
  {
    title: 'CREATORS',
    dataIndex: 'influencerUID',
    key: 'influencerUID',
    width: '220px',
    render: influencerUID => <Influencer.Avatar influencerUID={influencerUID} />,
    className: 'influencer-width-shipment',
  },
  {
    title: 'ADDRESS',
    dataIndex: 'influencer',
    key: 'address',
    render: Address,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: '281px',
    align: 'right',
    className: 'col-action actions',
    render: actions => <Action {...actions} />,
  },
];
/**
 * Shipment Table Component
 *
 * @typedef {{partnershipUIDs: string[], onClickShippingDetails: () => any, onClickViewProfile: (partnershipUID: string) => any}} ShipmentTableProps
 * @type {React.FC<ShipmentTableProps>}
 */
const ShipmentTable = ({ partnershipUIDs, onClickShippingDetails }) => {
  const partnerships = useSelector(selectors.getPartnerships());
  const influencers = useSelector(selectors.getInfluencers());

  const source = partnershipUIDs.map(partnershipUID => {
    const partnership = partnerships[partnershipUID];
    const influencer = influencers[partnership.influencerUID];

    return {
      key: partnershipUID,
      partnership,
      influencer,
      influencerUID: partnership.influencerUID,
      action: {
        partnership,
        influencerUID: partnership.influencerUID,
        showModal: () => onClickShippingDetails(partnershipUID),
        status: partnership.status,
      },
    };
  });

  return (
    <Table
      className="trend-table"
      columns={columns}
      dataSource={source}
      pagination={false}
      cellpadding="0"
      cellspacing="0"
    />
  );
};
export default ShipmentTable;
