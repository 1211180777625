import React from 'react';
import moment from 'moment';
import { Menu, Icon, Row, Col } from 'antd';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { ReactComponent as IconSettings } from 'images/icon-settings.svg';
import * as routes from 'constants/routes';
import { withCookies } from 'react-cookie';
import { useSession } from 'components/Session';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import InitalSideMenu from './InitalSideMenu';
import CampaignsSideMenu from './CampaignsSideMenu';

const CampaignMenus = ({ squishedNav }) => {
  const {
    brand,
    activeProductUIDs: activeProducts = [],
    completedProductUIDs: completedProducts = [],
  } = useSession();
  const products = useSelector(selectors.getProducts());
  const history = useHistory();
  const path = history.location.pathname.split('/');
  const ownedCampaigns = activeProducts;
  const noCampaigns = activeProducts.length === 0 && completedProducts.length === 0;
  const selectedKeys = path[1] || '';
  const { isAdmin, isContentAdmin } = brand;
  const { status, stripeCustomerId, lastPurchaseDate, approvedAt } = brand;
  const incomplete = !stripeCustomerId;
  const firstCampaign = products[ownedCampaigns[0]];
  const firstCampaignCompleted = firstCampaign && !incomplete;
  const onBoarding = status && !lastPurchaseDate;
  const onBoardingApproved = onBoarding && status === 'approved';
  const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

  const onBoardingActive =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') > moment();

  const expiredOnBoardingCampaign =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') < moment();

  return (
    <div className="side-menu">
      <div>{noCampaigns ? <InitalSideMenu /> : <CampaignsSideMenu />}</div>
      {!!completedProducts.length && (
        <div id="archive-link-container">
          <Link to="/campaigns/history" className="sub-menu-btn archive">
            <span style={{ padding: '6px 14px' }}>ARCHIVED PROJECTS</span>
          </Link>
        </div>
      )}
      <Row style={{ marginTop: '45px' }}>
        <Col xs={24} className="pl-3">
          <div className="menu-title">More From Trend</div>
        </Col>
      </Row>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKeys]}
        defaultOpenKeys={['campaigns']}
        className="trend-menu-inline secondary mb-6"
      >
        {(isAdmin || isContentAdmin) && (
          <Menu.Item key="admin/">
            <Link to="/admin">
              <Icon
                type="user"
                style={{ width: '25px', height: '25px', fontSize: '18px' }}
                className="ml-1"
              />
              <span className="hidden-mobile"> Access Employee Functions </span>
            </Link>
          </Menu.Item>
        )}
        {lastPurchaseDate && (
          <Menu.Item key="free-credits" className="sub-menu-item">
            <Link to="/free-credits/refer">
              <span className="box-border">$</span>
              <span className="hidden-mobile"> Earn Free Credits </span>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="help-center" className="sub-menu-item">
          <a target="_blank" href="https://support.soona.co/trend-ugc" rel="noopener noreferrer">
            <span
              className="box-border"
              style={{ fontSize: '22px', fontWeight: '600', lineHeight: '34px' }}
            >
              ?
            </span>
            <span className="hidden-mobile"> Help Center </span>
          </a>
        </Menu.Item>
      </Menu>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKeys]}
        defaultOpenKeys={['campaigns']}
        className={`trend-menu-inline settings ${(expiredOnBoardingCampaign || onBoardingActive) &&
          showMarqueNotif &&
          'with-notification'}`}
      >
        <Menu.Item key="store/edit">
          <Link to={routes.ACCOUNT_PATH} style={{ width: squishedNav ? 'inherit' : '210px' }}>
            <span className="box-border">
              <IconSettings style={{ width: '19px', height: '19px' }} />
            </span>
            {!squishedNav && <span>Account Settings</span>}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default compose(
  withRouter,
  withCookies
)(CampaignMenus);
