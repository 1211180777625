import moment from 'moment';
import { Avatar, Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import { selectors } from 'stores';
import Influencer from 'components/Influencers';

const columns = [
  {
    title: '',
    dataIndex: 'influencerUID',
    key: 'influencerUID',
    render: val => {
      return <Influencer.Avatar influencerUID={val} />;
    },
    className: 'avatar',
  },
  {
    title: '',
    dataIndex: 'lastMessage',
    key: 'lastMessage',
    className: 'message-col',
    align: 'center',
    render: message => (
      <div style={{ height: '30px' }}>
        <div style={{ width: '260px', textAlign: 'left' }}>
          <TextTruncate text={message} line={2} />
        </div>
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'lastMessageDate',
    key: 'lastMessageDate',
    className: 'time',
    render: val => {
      return moment.unix(val).fromNow();
    },
  },
  {
    title: '',
    dataIndex: 'isUnread',
    key: 'isUnread',
    className: 'count',
    render: hasUnread =>
      hasUnread ? (
        <Avatar
          style={{
            backgroundColor: '#ffb917',
            verticalAlign: 'middle',
            marginLeft: '17px',
            lineHeight: '28px',
          }}
        >
          1
        </Avatar>
      ) : (
        <div
          style={{
            verticalAlign: 'middle',
            marginLeft: '17px',
            lineHeight: '28px',
          }}
        >
          &nbsp;
        </div>
      ),
  },
];
/**
 * Message List Component
 *
 * @typedef {{ messageUIDs: string[], onViewMessage: (messageUID) => any, showCheckbox: boolean, onRowSelect: (val) => any }} ListProps
 * @type {React.FC<ListProps>}
 */
const List = ({ messageUIDs, onViewMessage, onRowSelect, showCheckbox }) => {
  const messages = useSelector(selectors.getMessages());
  const influencers = useSelector(selectors.getInfluencers());
  if (messageUIDs.length === 0) {
    return <div />;
  }

  const source = messageUIDs.map(messageUID => {
    const message = messages[messageUID];
    const { influencerUID, lastMessage, lastMessageDate, brandUID } = message;
    const { state } = influencers[influencerUID];
    // Handle undefined messages
    const { hasUnread = false } = message.users[brandUID] || {};

    return {
      key: messageUID,
      lastMessageDate,
      lastMessage,
      influencerUID,
      isUnread: hasUnread,
      influencerState: state,
      onViewMessage: () => onViewMessage(messageUID),
    };
  });

  const rowSelection = record => {
    return {
      onClick: e => {
        e.preventDefault();
        return onViewMessage(record.key);
      },
      onChange: e => showCheckbox && onRowSelect(e),
    };
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={source}
        className="trend-table"
        showHeader={false}
        pagination={false}
        onRow={rowSelection}
      />
    </div>
  );
};

export default List;
